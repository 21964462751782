import React from 'react';

const ToolbarActions = ({
  className,
  ...props
}) => (
  <div className={`toolbar_actions ${className}`} {...props} />
);

ToolbarActions.defaultProps = {
  className: '',
};

export default ToolbarActions;
