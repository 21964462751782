import React, {Component} from 'react';
import Homepage from '../homepage/Homepage.jsx';
import Login from '../auth/Login.jsx';
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from '../../PrivateRoute.jsx';
import {auth} from '../../utils/auth.js';

class App extends Component {
  state = {
    selectedDir: null
  }
  render() {
    return (
      <Router>
        <Route exact path='/' component={Login}/>
        <PrivateRoute path='/home' selectedDir={this.state.selectedDir} component={Homepage}/>
      </Router>
    );
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let selectedDir = {
      project: urlParams.get('project'),
      cell: urlParams.get('cell'),
      task: urlParams.get('task'),
      stimulus: urlParams.get('stimulus')
    }
    if (selectedDir.project && selectedDir.cell && selectedDir.task && selectedDir.stimulus)
      this.setState({selectedDir});
    auth.listenToChange();
  }
}

export default App;
