import firebase from '../firebase.js';
import emitter from '../emitter.js';

export const db = {
  add(file) {
    return firebase.firestore().collection('files').add(file);
  },

  getAll() {
    return firebase.firestore().collection("files").get().then(querySnapshot => {
      let list = [];
      querySnapshot.forEach(doc => {
        list.push({...doc.data(), id: doc.id});
      });
      return Promise.resolve(list);
    });
  },

  get(id) {
    let file = firebase.firestore().collection('files').doc(id);
    return file.get().then(data => Promise.resolve(data.data()));
  },

  update(id, data) {
    return firebase.firestore().collection("files").doc(id).set(data, { merge: true })
      .then(response => {
        return Promise.resolve(true)
      })
      .catch(err => console.log('error', err))
      .finally(() => Promise.resolve(true));
  },

  initStatusListener(file) {
    var userStatusDatabaseRef = firebase.database().ref('/status/' + file.id);
    var isOfflineForDatabase = {
        ...file,
        active: false,
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    var isOnlineForDatabase = {
        ...file,
        active: true,
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    firebase.database().ref('.info/connected').on('value', function(snapshot) {
        // if (snapshot.val() == false) {
        //     return;
        // };
    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
    });
    var userStatusFirestoreRef = firebase.firestore().doc('/files/' + file.id);
    var isOfflineForFirestore = {
        ...file,
        active: false,
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };

    var isOnlineForFirestore = {
        ...file,
        active: true,
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };

    firebase.database().ref('.info/connected').on('value', function(snapshot) {
        if (snapshot.val() == false) {
            userStatusFirestoreRef.set(isOfflineForFirestore);
            return;
          }
        //   else {
        //    userStatusFirestoreRef.set(isOnlineForFirestore);
        // };

        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            userStatusDatabaseRef.set(isOnlineForDatabase);
            userStatusFirestoreRef.set(isOnlineForFirestore);
        });
    });
  },

  listenForChanges() {
    firebase.firestore().collection("files")
    .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
            if (change.type === "added") {
            //    console.log("New file: ", change.doc.data());
            }
            if (change.type === "modified") {
              //  console.log("Modified file: ", change.doc.data());
                emitter.emit('fileUpdated', change.doc.data());
            }
            if (change.type === "removed") {
          //      console.log("Removed file: ", change.doc.data());
            }
        });
    });
  }
}
