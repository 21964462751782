import React, {Component} from 'react';
import {Label, FormControl, Button} from '../common';

export default class FileStateDialogue extends Component {
  constructor(props) {
    super(props);
    this.rejectReasons = [
      'no face',
      'photo',
      'dark / light',
      'eat / talk',
      'duplicate',
      'tester',
      'other'
    ]
    this.state = {
      file: JSON.parse(JSON.stringify(this.props.file))
    }
  }

  onSetFileState = e => {
     e.preventDefault();
     if (JSON.stringify(this.state.file) === JSON.stringify(this.props.file)) {
       this.props.closeStateDialogue();
       return;
     }
     let file = JSON.parse(JSON.stringify(this.state.file));
     this.props.updateFileState(file);
   }

  onCloseStateDialogue = e => {
     e.preventDefault();
     e.stopPropagation();
     this.props.closeStateDialogue();
   }

   changeFileState = e => {
     let file = {...this.state.file};
     file[e.target.name] = e.target.value;
     this.setState({file});
   }

   setRejectReason = e => {
     let file = {...this.state.file};
     file.reject_reason = e.target.id;
     this.setState({file});
   }

  render () {
    let {visible, top, left} = this.props;
    let {file} = this.state;
    let isReadOnly = this.props.file.state === 'C' || this.props.file.state === 'R';
    let style = {
      display: visible ? 'block': 'none',
      top,
      left
    };
    let rejectReasonsStyle = {display: this.state.file.state === 'R' ? 'block': 'none'}
    let cleaned = (
      this.props.file.state ? (
        <FormControl>
          <input type="radio" name="state" value="C" id="c" checked={this.state.file.state === 'C'} disabled={isReadOnly} onChange={this.changeFileState}/>
          <Label htmlFor="c">cleaned</Label>
        </FormControl>
      ) : null
    )
    return (
      <div className="file-state-dialogue" style={style}>
        <div className="fsd-title">
          <Label>Set file state:</Label>
        </div>

        <div className="fsd-body">
          {cleaned}
          <FormControl>
            <input type="radio" name="state" value="R" id="r" checked={this.state.file.state === 'R'} disabled={isReadOnly} onChange={this.changeFileState}/>
            <Label htmlFor="r">rejected</Label>
          </FormControl>

          <div className="reject-reasons" style={rejectReasonsStyle}>
            <div className="reject-reasons-title">
              <Label>Reject reason(s): </Label>
            </div>
            <div className="reject-reasons-body">

            {
              this.rejectReasons.map((reason, i) => {
                return (
                  <FormControl key={i}>
                    <input type="radio" name="reject-reason" id={reason} disabled={isReadOnly} checked={file.reject_reason === reason} onChange={this.setRejectReason}/>
                    <Label htmlFor={reason}>{reason}</Label>
                  </FormControl>
                )
              })
             }
            </div>

            <div className="reject-reasons-title">
              <Label>Other: </Label>
            </div>
            <div className="reject-reasons-body">
              <FormControl>
                <textarea  disabled={isReadOnly} value={this.state.file.reject_reason_other} name="reject_reason_other" onChange={this.changeFileState}/>
              </FormControl>
            </div>
          </div>

        </div>

        <div className="fsd-footer">
          <Button className="btn-sm" onClick={this.onCloseStateDialogue}>Cancel</Button>
          <Button className="btn--primary btn-sm" onClick={this.onSetFileState}>Apply</Button>
        </div>
      </div>
    );
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.file) !== JSON.stringify(this.props.file))
     this.setState({file: JSON.parse(JSON.stringify(this.props.file))})
  };
}
