import React, {Component, Fragment} from 'react';
import draggable from './draggable.js';
import emitter from '../../emitter';

export default class Slider extends Component {
  componentDidMount() {
    draggable(this.from, {onStartDragging: this.onStartDragging, onDrag: this.onDragFrom, onStopDragging: this.onStopDragging}, {limit: {from: () => 3, to: this.calcLimitFrom}});
    draggable(this.to, {onStartDragging: this.onStartDragging, onDrag: this.onDragTo, onStopDragging: this.onStopDragging}, {limit: {from: this.calcLimitToL, to: this.calcLimitTo}});
    draggable(this.range, {onStartDragging: this.onStartDragging, onDrag: this.onDragRange, onStopDragging: this.onStopDragging}, {limit: {from: () => 3, to: this.calcLimitRange}});
    emitter.on('timeUpdated', this.onTimeUpdate);
    emitter.on('updateFrame', this.onFrameUpdate);
  }

  componentWillUnmount() {
    emitter.off('timeUpdated', this.onTimeUpdate);
    emitter.off('updateFrame', this.onFrameUpdate);
  }

  onTimeUpdate = time => {
    let currFrame  = Math.round(time * this.props.fps);
    this.onFrameUpdate(currFrame);
  }

  onFrameUpdate = currFrame => {
    let diff = this.props.range.to - this.props.range.from;
    if (currFrame >= this.props.range.to && currFrame !== 0) {
      let range = {from: currFrame + 1 - diff, to: currFrame + 1};
      this.props.zoomChart(range);
      this.moveSlider(range);
    }

    if (currFrame <= this.props.range.from && currFrame !== 0) {
      let range = {from: currFrame - 1, to: currFrame - 1 + diff};
      this.props.zoomChart(range);
      this.moveSlider(range);
    }
  }

  moveSlider = range => {
    let w = document.getElementById('slider').getBoundingClientRect().width;
    let dotWidth = w / this.props.dots;
    this.from.style.left = Math.floor(range.from * dotWidth) + 'px';
    this.to.style.left = Math.floor(range.to * dotWidth) + 'px';
    this.range.style.left = parseInt(this.from.style.left) + 5 + 'px';
    this.range.style.width = parseInt(this.to.style.left) - parseInt(this.from.style.left) - 5 + 'px';
  }

  onStartDragging = () => {
//    this.props.deselectAll();
    document.getElementById('time-dot').style.display = "none";
    document.getElementById('dot-from').style.display = "none";
    document.getElementById('dot-to').style.display = "none";
  }

  onDragFrom = e => {
   let diff = parseInt(this.from.style.left);
   this.range.style.left = diff + this.from.getBoundingClientRect().width + 'px';
   this.range.style.width = (this.to.getBoundingClientRect().left - this.from.getBoundingClientRect().right) + 'px';
  }

  onDragTo = e => {
     this.range.style.width = (this.to.getBoundingClientRect().x - this.range.getBoundingClientRect().x)  + 'px';
  }

  onDragRange = e => {
    this.from.style.left = parseInt(this.range.style.left) - this.to.getBoundingClientRect().width + 'px';
    this.to.style.left = this.range.getBoundingClientRect().width + parseInt(this.range.style.left) + 'px';
  }

  onStopDragging = () => {
    let from = parseInt(this.from.style.left);
    // eslint-disable-next-line
    if (from !== from) from = 0;

    let to = parseInt(this.to.style.left) + this.to.getBoundingClientRect().width;
    // eslint-disable-next-line
    if (to !== to) to = 0;
    let w = document.getElementById('slider').getBoundingClientRect().width;
    let dotWidth = w / this.props.dots;
    from /= dotWidth;
    if ( from < 0) {
      from = 0;
    }
    let range = {from: Math.floor(from), to: Math.floor(to / dotWidth)};
    this.props.zoomChart(range);
    // this.props.deselectAll();
    this.props.repositionSelection(from, to);
  }

  calcLimitFrom = () => {
    let to = parseInt(this.to.style.left);
    // eslint-disable-next-line
    if (to !== to) to = 55;
    return to - 10;
  }

  calcLimitTo = () => {
//    console.log(this.to.getBoundingClientRect(), document.getElementById('slider').getBoundingClientRect())
    let sliderPos = document.getElementById('slider').getBoundingClientRect();
    let toPos = this.to.getBoundingClientRect();
    return sliderPos.width - toPos.width;
  }

  calcLimitToL = () => {
//    console.log(this.to.getBoundingClientRect(), document.getElementById('slider').getBoundingClientRect())
    let from = parseInt(this.from.style.left);
    // eslint-disable-next-line
    if (from !== from) from = 0;
    return from + 10;
  }

  calcLimitRange = () => {
    let sliderPos = document.getElementById('slider').getBoundingClientRect();
    let toPos = this.to.getBoundingClientRect();
    let rangePos = this.range.getBoundingClientRect();
    return sliderPos.width - rangePos.width - toPos.width;
  }

  render() {
    return (
      <Fragment>
        <div id="slider">
          <div className="range-wrapper" >
            <div className="from" ref={node => this.from = node}></div>
            <div className="range"  ref={node => this.range = node}></div>
            <div className="to" ref={node => this.to = node}></div>
          </div>
        </div>
        <div className="range-info">{this.props.range.from} - {this.props.range.to}</div>
      </Fragment>
    )
  }
}
