import React, {Component, Fragment} from 'react';
import Video from './Video.jsx';
import emitter from '../../emitter';

export default class RespondentsVideo extends Component {
  duration = 0;
  numOfFrames = 0;
  frameInterval = null;
  currentFrame = 0;
  player=null;

  videoJSOptions = {
    controls: true,
    sources: [{
      src: "https://player.vimeo.com/external/196344457.sd.mp4?s=73d5e633a3c43c365fb5120f42335a4a676b1dd7&profile_id=164",
      type: 'video/mp4'
    }],
    loop: false,
    video: {frameRate: { ideal: 20, max: 20 }},
    playbackRates: [1, 2, 3, 5, 10],
    controlBar: {
     fullscreenToggle: false,
     pictureInPictureToggle: false
   },
   width: 640,
   height:480
  }

  startFrameCheck = () => {
    const interval = 1000 / this.props.fps;
    window.isPlaying = true;
    this.frameInterval = window.setInterval(() => {
      emitter.emit('getCurrentTime');
    }, interval);
  }

  stopFrameCheck = () => {
    this.clearFrameInterval();
  }

  clearFrameInterval = () => {
    window.clearInterval(this.frameInterval);
  }

  onVideoEnd = () => {
    this.clearFrameInterval();
  }

  getCurrentTime = ct => {
    this.props.updateFrame(Math.floor(ct * this.props.fps));
  }

  render() {
    return (
      <Fragment>
        <Video options={this.videoJSOptions}
               setDuration={this.props.setDuration}
               onPlayerStart={this.startFrameCheck}
               onPlayerPause={this.stopFrameCheck}
               onVideoEnd={this.onVideoEnd}
               getCurrentTime={this.getCurrentTime}
               isPlaying={this.props.isPlaying}
               onPause={this.props.onPause}
               onPlay={this.props.onPlay}
               id="respondent"
               />
      </Fragment>
    )
  };
}
