import React from 'react';
import classnames from 'classnames';

const Button = ({
  as: T,
  className,
  ...props
}) => <T className={classnames('btn', {[className]: className})} {...props} />

Button.defaultProps = {
  as: 'button',
};

export default Button;
