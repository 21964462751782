import React, {Component} from 'react';
import {reducer} from './reducer';
import Panel from '../../components/panel';
import {api} from '../../api';

export default class extends Component {
  state = reducer.init();

  componentDidMount() {
    this.getData();
  }

  onTimeUpdate = (currentTime) => {
    this.setState(reducer.updateTime(currentTime));
  };

  onPause = () => {
    this.setState(reducer.pauseVideo());
  };

  onPlay = () => {
    this.setState(reducer.playVideo());
  };

  onSelect = (entry) => {
    this.setState(reducer.toggleEntry(entry));
  };

  onSelectRange = (e1, e2) => {
    this.setState(reducer.selectRange(e1, e2));
  };

  onEdit = data => {
    this.setState(reducer.editEntries(data), () => {
      this.onSave();
    });
  };

  onCancel = () => {
    this.setState(reducer.deselectAll());
  };

  deselectAll = () => {
    this.setState(reducer.deselectAll());
  };

  onNormalize = () => {
    this.setState(reducer.normalizeData());
  };

  getParams = () => {
    return {
      emo_user_key: localStorage.getItem('emo_user_key'),
      project: this.props.filepath.project,
      cell: this.props.filepath.cell,
      task: this.props.filepath.task,
      stimuli: this.props.filepath.stimulus,
      tester_id: this.props.filepath.userid.slice(0, this.props.filepath.userid.length - 4),
      worker_id: window.worker_id
    };
  };

  getData = () => {
    let params = this.getParams();
    api.getFile(params)
      .then(response => {
       window.worker_id = response.data.worker_id;
       this.setState(reducer.loadData(response.data));
       this.props.updateFPS(response.data.fps);
       this.props.updateStimuliFrames(response.data.frames);
      })
      .catch((err) => {
        this.props.onMessage(err.message);
      });
  };

  onSave = () => {
    const data = this.state.data.copy.map((entry, i) => {
      return {
        '': i,
        ...entry,
      };
    });
    let params = this.getParams();
    params.file = data;

    api.updateFile(params)
      .then((res) => {
        this.props.onSave();
        this.setState(reducer.saveData());
      })
      .catch((err) => {
        this.props.onMessage(err.message);
      });
  };

  onUndo = () => {
    this.setState(reducer.undoData());
  };

  generateProps = () => {
    const {data, selected} = this.state;
    return {
      ...this.props,
      ...this.state,
      onTimeUpdate: this.onTimeUpdate,
      onSelect: this.onSelect,
      onSelectRange: this.onSelectRange,
      onEdit: this.onEdit,
      onCancel: this.onCancel,
      onNormalize: this.onNormalize,
      onSave: this.onSave,
      onUndo: this.onUndo,
      onPlay: this.onPlay,
      onPause: this.onPause,
      deselectAll: this.deselectAll,
      chartData: data.copy.map((e) => ({
        ...e,
        selected: (selected.includes(e.t)),
      })),
      editorData: data.copy.filter(({t}) => (
        selected.includes(t)
      )).map(({t, ...entry}) => entry),
    };
  };

  render() {
    const props = this.generateProps();
    return (
      <Panel {...props} />
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filepath.userid !== this.props.filepath.userid){
      this.getData();
    }
  }
}
