import React from 'react';
import {Button, InputNumber, Title} from '../../common';
import EditorFormField from './field';

/**
 * Helper method, provides an event target interface.
 *
 * @param {string} name
 * @return {Object}
 */
const evt = (name) => ({
  target: {
    name,
    value: 1,
  }
});

const EditorForm = ({
  data,
  onChange,
  onEdit,
  onCancel,
  frames,
  isReadOnly
}) =>{

let rangeStyle = frames[0] === frames[frames.length - 1] ? {visibility: 'hidden'} : {visibility: 'visible'};
let rangeTitle = frames[0] === frames[frames.length - 1] ? 'frame': 'frame from';
return (
  <div style={{ padding: '0 12px' }}>
    <div className='frame-title'>
      <span>
        <Title>{rangeTitle}</Title>
        <InputNumber
          value={frames[0]}
          disabled
        />
      </span>
       <span style={rangeStyle}>
       <Title>frame to</Title>
       <InputNumber
         value={frames[frames.length - 1]}
         disabled
       />
       </span>
    </div>

    {Object.keys(data).map((key) => (
      <EditorFormField
        key={key}
        name={key}
        value={data[key]}
        onChange={onChange}
        onClick={() => onChange(evt(key), true)}
        isReadOnly={isReadOnly}
      />
    ))}
    <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
    }}>
      <Button style={{marginRight: 8}} onClick={onCancel}>Cancel</Button>
      <Button disabled={isReadOnly} onClick={onEdit}>Apply and Save</Button>
    </div>
  </div>
)};

export default EditorForm;
