import {compose, map, omit} from 'ramda';
import {mapObject, range} from '../../../utils/utils';

const prepareDate = map(compose(map(parseFloat), omit([''])));

export const reducer = {
  init() {
    return {
      data: {
        src: [],
        copy: [],
      },
      selected: [],
      touched: false,
      currentTime: 0,
      playing: false,
      frames: [],
      videopath: ''
    };
  },

  selectRange(e1, e2) {
    let selected = range(e1, e2);
    return {
      selected,
      frames: selected
    };
  },

  toggleEntry(entry) {
    if (!entry) return;
    return state => {
      return {
        selected: [entry.t],
        frames: [entry.t],
      };
    }
  },

  deselectAll() {
    return () => ({
      selected: [],
      frames: []
    });
  },

  editEntries(data) {
    return (state) => {
      const copy = state.data.copy.map((entry) => {
        if (!state.selected.includes(entry.t)) {
          return entry;
        }

        return {
          ...entry,
          ...data,
        };
      });

      return {
        data: {
          ...state.data,
          copy,
        },
        touched: true,
        selected: [],
      };
    };
  },

  normalizeData() {
    return (state) => {
      return {
        data: {
          ...state.data,
          copy: state.data.copy.map((entry) => mapObject(entry, (key, value) => {
            return (key === 't')
              ? value
              : (key === 'neutral') ? 1 : 0;
          })),
        },
        selected: [],
        touched: true,
      };
    };
  },

  updateTime(t) {
    return () => ({
      currentTime: t,
    });
  },

  loadData(rawData) {
    return () => {
      const data = prepareDate(rawData.csv);
      return {
        data: {
          src: data,
          copy: data,
        },
        videopath: rawData.video
      };
    };
  },

  saveData() {
    return (state) => ({
      data: {
        src: state.data.copy,
        ...state.data,
      },
      touched: false,
    });
  },

  undoData() {
    return (state) => ({
      data: {
        ...state.data,
        copy: state.data.src,
      },
      touched: false,
    });
  },

  playVideo() {
    return (state) => ({
      playing: true,
    });
  },

  pauseVideo() {
    return (state) => ({
      playing: false,
    });
  },

  setVideoPath(videopath) {
    return state => ({videopath})
  }
};
