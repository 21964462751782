import React, {Component} from 'react';
import ExplorerHeader from './header';
import Files from './Files.jsx';
import {Input, IconButton} from '../common';
import {db} from '../../data/files.js';
import {api} from '../../api';
import {fileStates} from '../../utils/file-states.js';
import emitter from '../../emitter.js';

class Explorer extends Component {
  state = {
    expanded: true,
    data: [],
    filteredData: [],
    searchFor: '',
    openedFile: {id: -1},
    isPlaying: false
  };

  onToggle = () => {
    this.setState((state) => ({
      expanded: !state.expanded,
    }));
  }

  setData = () => {
    let {project, cell, task, stimulus } = this.props.selectedDir;
    db.getAll()
      .then(allFiles => {
        let data = allFiles.filter(file => file.project === project && file.cell === cell && file.task === task && file.stimulus === stimulus && (file.userid.toLowerCase().indexOf(this.state.searchFor) >= 0) && (this.props.filtered.indexOf(fileStates[file.state].state) >= 0))
                             .map(file => ({name: file.userid, ...file}));
        this.setState({data, filteredData: data});
      });
  };

  onClick = (evt) => {
    const {path} = evt.target.dataset;
    this.props.onClick(path);
  };

  updateCount = data => {
    let count = {total: 0, C: 0, R: 0};
    data.forEach(file => {
      if (count[file.state])
        count[file.state]++;
      else {
        count[file.state] = 1;
      }
      count.total++;
    })
    this.props.onUpdateCount(count);
  }

  onFileUpdate = file => {
    let data = JSON.parse(JSON.stringify(this.state.data));
    let filteredData = JSON.parse(JSON.stringify(this.state.filteredData));
    for (let i = 0; i < data.length; i++) {
      if (data[i].name === file.userid) {
        data[i] = {...data[i], ...file};
        let filteredIdx = filteredData.findIndex(el => el.name === file.userid);
        filteredData[filteredIdx] = file;
        this.setState({data, filteredData});
        this.updateCount(data);
        return;
      }
    };
  };

  update = file => {
    db.update(file.id, file);
    let tester_id = file.userid.slice(0, file.userid.length - 4);
    let params = {
      ...this.props.selectedDir,
      worker_id: window.worker_id,
      emo_user_key: localStorage.getItem('emo_user_key'),
      file: '',
      rejected_reason: file.reject_reason || file.reject_reason_other,
      tester_id
    };
    api.updateFile(params)
      .then(response => {
        if (file.state === 'R') {
          params.rejected_reason = file.reject_reason || file.reject_reason_other;
          api.markAsRejected(params);
        } else if (file.state === 'C')
          api.markAsCleaned(params);
        this.props.updateReadOnly(file.state)
      })
  }

  debounce = (func, wait, immediate) => {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

  openFile = file => {
    if (window.isPlaying || (file.active && this.state.openedFile.id !== file.id)) return;
    this.closeFile(this.state.openedFile);
    if (file.id  !== -1) {
      file.active = true;
      this.debounce(() => db.initStatusListener(file), 250);
    }
    db.update(file.id, file);
    this.setState({openedFile: file});
    window.openedFile = file;
    this.props.onClick(file);
  }

  closeFile = file => {
    if (file.id !== -1)
      db.update(file.id, {active: false});
  }

  searchFor = e => {
    let searchFor = e.target.value.toLowerCase().trim();
    // let data = JSON.parse(JSON.stringify(this.state.data));
    // let filteredData = data.filter(file => file.name.toLowerCase().indexOf(searchFor) >= 0 && (this.props.filtered.indexOf(fileStates[file.state].state) >= 0));
    this.setState({searchFor});
    this.setData();
  }

  copyToClipboard = () => {
    let copyToClipboardEl = document.getElementById("copy-to-clipboard");
    let ids = this.state.filteredData.map(file => file.name.substring(0, file.name.length - 4)).join(',');
    copyToClipboardEl.value = ids;
    copyToClipboardEl.select();
    document.execCommand("copy");
  }

  render() {
    const {expanded, isPlaying} = this.state;
    const explorerClasses = 'explorer' + (isPlaying ? ' disabled': '');
    return (
      <div className={explorerClasses}>
        <ExplorerHeader
          name={this.props.selectedDir || 'data'}
          expanded={expanded}
          onClick={this.onToggle}
        />
        <span className="files-options">
          <Input className='file-search' type="text" placeholder="Search..." value={this.state.searchFor} onChange={this.searchFor}/>
          <IconButton onClick={this.copyToClipboard} title="Copy to clipboard">
            <i className="material-icons">file_copy</i>
          </IconButton>
          <input type="text" id="copy-to-clipboard"/>
        </span>

       <Files data={this.state.filteredData} update={this.update} openFile={this.openFile} openedFile={this.state.openedFile}/>
      </div>
    );
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.selectedDir) !== JSON.stringify(this.props.selectedDir) || JSON.stringify(prevProps.filtered) !== JSON.stringify(this.props.filtered) || JSON.stringify(prevProps.docs) !== JSON.stringify(this.props.docs))
      this.setData();
  }

  componentDidMount() {
    emitter.on('fileUpdated', this.onFileUpdate);
    emitter.on('playStateChange', isPlaying => this.setState({isPlaying}));
    window.onbeforeunload = () => this.closeFile(this.state.openedFile);
  }

  componentWillUnmount() {
    emitter.off('fileUpdated', this.onFileUpdate);
    emitter.off('playStateChange', isPlaying => this.setState({isPlaying}));
  }
}

export default Explorer;
