import React, {Component, Fragment} from 'react';
import Video from './Video.jsx';

export default class StimulusVideo extends Component{
  videoJSOptions = {
    controls: false,
    width: 640,
    height:480,
    sources: [{
      src: this.props.stimulusPath,
      type: 'video/mp4'
    }]
  }

  render() {
    return (
      <Fragment>
        <Video options={this.videoJSOptions} id="stimulus"/>
      </Fragment>
    )
  }
}
