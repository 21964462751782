import React from 'react';
import classnames from 'classnames';

const Input = ({
  type,
  className,
  ...props
}) => (
  <input
    className={classnames('input-field', {[className]: className})}
    type={type}
    {...props}
  />
);

Input.defaultProps = {
  type: 'text',
};

export default Input;
