import React from 'react';
import EditorMulty from './multy';
import EditorSingle from './single';

// * NOTE *
// `EditorMulty` and `EditorSingle` are pretty much the same component,
// except the initial state. `EditorSingle` uses `data` prop, while
// `EditorMulty` uses normalized `data` for the initial state.
// Since `data` is only used in the constructor, change in props
// doesn't cause re-render.
const Editor = ({
  data,
  ...props
}) => {
  return (
  (data && data.length)
    ? (data.length > 1)
      ? <EditorMulty data={data[0]} {...props} />
      : <EditorSingle data={data[0]} {...props} />
    : null
)};

export default Editor;
