import React, {Component, Fragment} from 'react';
import {Input, Button} from '../../components/common';
import Toast from '../../components/Toast';
import {auth} from "../../utils/auth.js";
import {removeAt} from '../../utils/utils';
import logo from '../../assets/logo.png';
import spinner from '../../assets/spinner.gif';
import {api} from '../../api';

export default class Login extends Component {
  state = {
    email: '',
    password: '',
    messages: [],
    isLoading: false
  }

  setValue = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  login = e => {
    e.preventDefault();
    this.setState({isLoading: true});

    api.login({username: this.state.email, pass: this.state.password})
      .then(response => {
        localStorage.setItem('emo_user_key', response.data.emo_user_key);
        auth.login(this.state.email, this.state.password)
          .then(response => {
            this.setState({isLoading: false});
            this.props.history.push('/home');
          })
          .catch(err => {
            this.onMessage('Invalid credentials. Please, try again.');
          });
      });

  };

  onMessage = (msg) => {
    this.setState({
      messages: this.state.messages.concat(msg),
    });
  };

  dismiss = (index) => {
    this.setState({
      messages: removeAt(this.state.messages, index),
    });
  };

  render () {
    return (
      <div className="login">
        {
          this.state.isLoading ?
            <div className="spinner-wrapper">
              <img src={spinner} />
            </div>
            :
            <Fragment>
              <img src={logo} alt="EyeSee" className="logo"/>
              <form onSubmit={this.login} method="POST">
                <Input placeholder="E-mail" value={this.state.email} onChange={this.setValue} name="email"/>
                <Input placeholder="Password" type="password" value={this.state.password} onChange={this.setValue} name="password"/>
                <Button className="btn--primary">Log in</Button>
              </form>

              <div className="toast-container">
                {
                  this.state.messages.map((message, i) =>
                  <Toast
                    key={i}
                    message={message}
                    onDismiss={() => this.dismiss(i)}
                  />)
                }
              </div>
            </Fragment>
        }
      </div>
    )
  }
}
