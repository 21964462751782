import React, {Component} from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer
} from 'recharts';
import Legend from './legend';
import Dots from './Dots.jsx';
import Slider from './Slider.jsx';
import {keys} from 'ramda';
import {removeAt, findIndexByKey} from '../../utils/utils';
import emitter from '../../emitter.js';

let width = 500;
const COLORS = {
  happy: '#ffef83',
  sad: '#57bae3',
  disgust: '#aee68e',
  puzzled: '#cca4c4',
  surprised: '#83b4d7',
  neutral: '#aaaaab',
  afraid: '#3b1b5b',
  affraid: '#3b1b5b',
};

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      timeX: 63,
      range: {
        from: 1,
        to: 50
      }
    };

    this.wrap = React.createRef();
    this.test = React.createRef();
    this.moveRange = true;
  }

  componentDidMount(){
    width = document.getElementById('media-materials').getBoundingClientRect().width;
    emitter.on('timeUpdated', this.onTimeUpdate);
    emitter.on('dragEnd', this.selectInterval);
    emitter.on('updateFrame', this.updateFrame);
  }

  componentWillUnmount () {
    emitter.off('timeUpdated', this.onTimeUpdate);
    emitter.off('dragEnd', this.selectInterval);
    emitter.off('updateFrame', this.updateFrame);
  }

  applyFilter = (filter) => {
    this.setState({
      filter: this.state.filter.concat(filter),
    });
  };

  removeFilter = (filter) => {
    const index = this.state.filter.indexOf(filter);
    this.setState({
      filter: removeAt(this.state.filter, index),
    });
  };

  onClick = ({value: filter}) => {
    const isApplied = this.state.filter.includes(filter);
    if (isApplied) {
      this.removeFilter(filter);
      return;
    }
    this.applyFilter(filter);
  };

  updateFrame = frame => {
    let range = {...this.state.range};
    let diff = range.to - range.from;
    if (frame <= range.from && frame > 0) {
      range.from = frame - 1;
      range.to = range.from + diff;
      this.setState({range});
    }
    if (frame >= range.to && frame < window.totalFrames) {
      range.to = frame + 1;
      range.from = range.to - diff;
      this.setState({range});
    }

    emitter.emit('setCurrentTime', frame/this.props.fps );
    window.currentFrame = frame;
  }

  onSelect = (e, a) => {
    if (!this.test.current.state || !e) return;
     Dots.setStyle(Dots.getDot('dot-from'), {display: 'block', left: (e.activeCoordinate.x - 8) + 'px'});
     Dots.setStyle(Dots.getDot('dot-to'), {display: 'block', left: (e.activeCoordinate.x - 8) + 'px'});
     document.getElementById('time-dot').style.display = 'block';
     let t = e.activeLabel;
     let entry = this.props.data.find(entry => entry.t === t)
     this.props.onSelect(entry);
     this.setState({timeX: e.activeCoordinate.x});

     if (entry.t/this.props.fps > 0.15)
       emitter.emit('setCurrentTime', entry.t/this.props.fps );
    // emitter.emit('updateFrame', e.activeLabel);
  };

  onTimeUpdate = time => {
    if (!this.test.current.state) return;
    let value =  Math.floor(time * this.props.fps);
    let index = findIndexByKey(this.test.current.state.tooltipTicks, value, 'value');
    if (!this.test.current.state.tooltipTicks[index]) return;
    let timeX = this.test.current.state.tooltipTicks[index].coordinate;
    if (this.moveRange) {
      Dots.getDot('dot-from').style.left = (timeX - 8) + 'px';
      Dots.getDot('dot-to').style.left = (timeX - 8) + 'px';
    }
     this.setState({timeX});
     this.moveRange = true;
  }

  selectInterval = el => {
    if (!el.classList.contains('dot')) return;
    let from = parseFloat(Dots.getDot('dot-from').style.left) + Dots.getDot('dot-from').getBoundingClientRect().width / 2 + 1;
    let to = parseFloat(Dots.getDot('dot-to').style.left) + Dots.getDot('dot-to').getBoundingClientRect().width / 2 + 1;
    let i1 = this.findNearestCoordinate(from, this.test.current.state.tooltipTicks);
    let i2 = this.findNearestCoordinate(to, this.test.current.state.tooltipTicks);
    let value1 = this.test.current.state.tooltipTicks[i1].value;
    let value2 = this.test.current.state.tooltipTicks[i2].value;
    this.props.onSelectRange(value1, value2);
  }

  findNearestCoordinate (el, arr) {
    let nearestEl = 0;
    let minDiff = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].coordinate > el) return nearestEl;
      let currDiff = el - arr[i].coordinate;
      if (currDiff <= minDiff) {
        minDiff = currDiff;
        nearestEl = i;
      }
      if (currDiff < 0) return nearestEl;
    }
    return nearestEl;
  }

  goToFrame = e => {
    if (!e) return;
    this.setState({timeX: e.coordinate});
    emitter.emit('setCurrentTime', e.value/this.props.fps );
    emitter.emit('updateFrame', e.value);
    this.moveRange = false;
    this.props.deselectAll();
  }

  zoomChart = range => {
    this.setState({range});
  }

  repositionSelection = (rangeFrom, rangeTo) => {
    let selectedFrom = this.props.selected[0];
    let selectedTo = this.props.selected[this.props.selected.length - 1];
    let dotFromPosition = 0;
    let dotToPosition = 0;
    let dotFrom = document.getElementById('dot-from');
    let dotTo = document.getElementById('dot-to');
    let timeDot = document.getElementById('time-dot');

    for (let i = 0; i < this.test.current.state.tooltipTicks.length; i++) {
      let el = this.test.current.state.tooltipTicks[i];
      if (el.value === selectedFrom)
        dotFromPosition = el.coordinate;
      if (el.value === selectedTo)
        dotToPosition = el.coordinate;
      if (dotFromPosition && dotToPosition) break;
    }

    if (selectedFrom >= rangeFrom) {
      timeDot.style.display = "block";
      dotFrom.style.display = "block";
      timeDot.setAttribute('cx', dotFromPosition);
      dotFrom.style.left = `${dotFromPosition - 8}px`;
    }
    if (selectedTo <= rangeTo) {
      dotTo.style.display = "block";
      dotTo.style.left = `${dotToPosition - 8}px`;
    }
  }

  render() {
    const {data, isPlaying} = this.props;
    let filteredData = data.filter(el => el.t <= this.state.range.to && el.t >= this.state.range.from);
    let chartStyle = isPlaying ? {pointerEvents: 'none'} : {pointerEvents: 'auto'};
    const labels = (keys(data[0]))
      .filter((s) => s !== 't' && s !== 'selected')
      .map((label) => ({
        selected: this.state.filter.includes(label),
        color: COLORS[label],
        value: label,
      }));

    const bars = labels
      .filter((s) => !s.selected);

    return (
      <div>
        <Legend labels={labels} onClick={this.onClick} />
        <div className='chart' ref={this.wrap} style={chartStyle}>
          <div className='chart_wrap'>
          <ResponsiveContainer width={'100%'} height={220}>
            <AreaChart data={filteredData}  onClick={this.onSelect} ref={this.test}>
              <XAxis dataKey='t' onClick={this.goToFrame}/>
              <YAxis type="number" domain={[0, 1]}/>
              <CartesianGrid strokeDasharray='3 3' vertical={false}/>
                {
                  bars.map((label, i) =>
                  <Area
                    type="monotone"
                    stackId='1'
                    key={label.value}
                    dataKey={label.value}
                    isAnimationActive={false}
                    fill={label.color}
                    stroke={label.color}
                    color={label.color}
                    width={width}
                  />)
                }
              <circle cx={this.state.timeX} cy={185} r={5} stroke="blue" strokeWidth={3} fill="blue" id="time-dot"/>
            </AreaChart>
           </ResponsiveContainer>
           <Slider dots={data.length} fps={this.props.fps} zoomChart={this.zoomChart} range={this.state.range} deselectAll={this.props.deselectAll} repositionSelection={this.repositionSelection}/>
           <Dots />
          </div>
        </div>
      </div>
    );
  };
}

export default Chart;
