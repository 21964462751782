import React, {Fragment} from 'react';
import {IconButton} from '../../common';
import {auth} from '../../../utils/auth';

const PanelAside = (props) => (
  <Fragment>
    <aside className='panel_aside' {...props} />
    <IconButton style={{position: 'fixed', right: '2vw', bottom: '0vh', width: '7vw', height: '7vw'}} onClick={auth.logout}>
      <i className="material-icons" style={{fontSize: '7vw'}}>power_settings_new</i>
    </IconButton>
  </Fragment>
);

export default PanelAside;
