import emitter from '../../emitter';

export default function draggable(el, cb = null, options = {}) {
  let pos1 = 0;
  let pos2 = 0;
  el.onmousedown = startDragging;

  function startDragging(e) {
  //  console.log('start dragging', this);
    e = e || window.event;
    e.preventDefault();
    pos2 = e.clientX;
    window.document.onmouseup = stopDragging;
    window.document.onmousemove = drag;
    if (cb && cb.onStartDragging) cb.onStartDragging();
  };

  function drag(e) {
  //  console.log('drag')
    e = e || window.event;
    e.preventDefault();
    pos1 = pos2 - e.clientX;
    pos2 = e.clientX;
//    console.log('test ', el.offsetLeft - pos1);
    if (options.limit && (el.offsetLeft - pos1 <= options.limit.from() || el.offsetLeft - pos1 >= options.limit.to()) ) return;
    el.style.left = (el.offsetLeft - pos1) + "px";

    if (cb && cb.onDrag) cb.onDrag();
  };

  function stopDragging(e) {
  //  console.log('stop dragging', el.style.left)
    emitter.emit('dragEnd', el);

    document.onmouseup = null;
    document.onmousemove = null;
    if (cb && cb.onStopDragging) cb.onStopDragging(e, el);
  };
}
