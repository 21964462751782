import axios from './config.js';

export const api = {
  login(data) {
    return axios({
      url: '/login',
      method: 'POST',
      data
    });
  },

  logout(data) {
    return axios({
      url: '/logout',
      method: 'POST',
      data
    });
  },

  fetchProjects(params) {
    return axios({
      url: '/projects',
      params
    });
  },

  fetchTesters(params) {
    return axios({
      url: '/project/testers',
      params
    });
  },

  getFile(params) {
    return axios({
      url: '/project/stimuli',
      params
    });
  },

  updateFile(data) {
    return axios({
      url: '/project/stimuli',
      method: 'POST',
      data
    });
  },

  markAsCleaned(data) {
    return axios({
      url: '/project/stimuli/cleaned',
      method: 'POST',
      data
    });
  },

  markAsRejected(data) {
    return axios({
      url: '/project/stimuli/rejected',
      method: 'POST',
      data
    });
  },

  export(params) {
    return axios({
      url: '/project/export/csv',
      params,
      responseType:'arraybuffer'
    });
  },


  downloadVideos(params) {
    return axios({
      url: '/project/export/videos',
      params,
      responseType:'arraybuffer'
    });
  }

}
