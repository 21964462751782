export default {
  SAVE: 'M19,12v7H5v-7H3v7c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7H19z M13,12.7l2.6-2.6l1.4,1.4l-5,5l-5-5l1.4-1.4l2.6,2.6V3h2V12.7 z',
  UNDO: 'M12.5,8c-2.6,0-5.1,1-6.9,2.6L2,7v9h9l-3.6-3.6c1.4-1.2,3.2-1.9,5.1-1.9c3.5,0,6.5,2.3,7.6,5.5l2.4-0.8 C21.1,11,17.1,8,12.5,8z',
  CLOSE: 'M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z',
  TIMES_3: 'M17.5,12c0.8,0,1.5,0.7,1.5,1.5V15c0,1.1-0.9,2-2,2h-4v-2h4v-2h-2v-2h2V9h-4V7h4c1.1,0,2,0.9,2,2v1.5 C19,11.3,18.3,12,17.5,12z M9.9,9L8,10.9L6.1,9L5,10.1L6.9,12L5,13.9L6.1,15L8,13.1L9.9,15l1.1-1.1L9.1,12l1.9-1.9L9.9,9z',
  TIMES_2: 'M9.9,9L8,10.9L6.1,9L5,10.1L6.9,12L5,13.9L6.1,15L8,13.1L9.9,15l1.1-1.1L9.1,12l1.9-1.9L9.9,9z M19,15h-4v-2h2 c1.1,0,2-0.9,2-2V9c0-1.1-0.9-2-2-2h-4v2h4v2h-2c-1.1,0-2,0.9-2,2v4h6V15z',
  TIMES_1: 'M9.9,9L8,10.9L6.1,9L5,10.1L6.9,12L5,13.9L6.1,15L8,13.1L9.9,15l1.1-1.1L9.1,12l1.9-1.9L9.9,9z M17,17h2V7h-4v2h2V17z',
  PAUSE: 'M6,19h4V5H6V19z M14,5v14h4V5H14z',
  PLAY: 'M8,5v14l11-7L8,5z'
};
