import React from 'react';
import {
  FormControl,
  InputNumber,
  IconButton,
  Label
} from '../../../common';

const EditorFormField = ({
  name,
  value,
  onChange,
  onClick,
  isReadOnly
}) => (
  <FormControl>
    <Label>{name}</Label>
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <InputNumber
        name={name}
        value={value}
        onChange={onChange}
        style={{flexGrow: 1}}
        step={0.01}
        readOnly={name === 'neutral' || isReadOnly}
      />
      <IconButton
        title='Normalize'
        style={{marginLeft: 8}}
        onClick={onClick}
        disabled={isReadOnly}
      >1</IconButton>
    </div>
  </FormControl>
);

export default EditorFormField;
