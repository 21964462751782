import firebase from '../firebase.js';
import {api} from '../api';
import {db} from '../data/files.js';


export const auth = {
  initPresenceListener() {
    var uid = firebase.auth().currentUser.uid;
    console.log("FB UID ", uid)
    var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
    var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    var isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    firebase.database().ref('.info/connected').on('value', function(snapshot) {
        if (snapshot.val() == false) {
            return;
        };
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
  },

  login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then(response => {
    //    auth.initPresenceListener();
        return Promise.resolve(true);
      })
      .catch(err => Promise.reject(false))
  },

  eyeseeLogout() {
    let emo_user_key = localStorage.getItem('emo_user_key');
    api.logout({emo_user_key}).finally(() => {
      localStorage.removeItem('emo_user_key');
      window.location.href='/';
    });
  },

  logout() {
    if (window.openedFile && window.openedFile.id !== -1) {
      db.update(window.openedFile.id, {active: false})
      .finally(() => {
          auth.eyeseeLogout();
          return firebase.auth().signOut();
        });
    } else {
      auth.eyeseeLogout();
      return firebase.auth().signOut();
    }
  },

  isLoggedIn() {
    let isLoggedIn = firebase.auth().currentUser && localStorage.getItem('emo_user_key');
    return isLoggedIn;
  },

  listenToChange() {
    firebase.auth().onAuthStateChanged(authUser => {
      authUser
        ? localStorage.setItem('authUser', JSON.stringify(authUser))
        : localStorage.removeItem('authUser')
    });
  }
}
