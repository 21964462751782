import React from 'react';

const ExplorerHeader = ({
  name,
  expanded,
  onClick,
}) => {
  let style = name.project ? {display: 'block'} : {display: 'none'};
  return (
    <div className='explorer_item explorer_item--folder'
      onClick={onClick}
      style={style}
    >
      <div className="explorer-title">
        <div className="folder">
          <p> - </p>
          <p> {name.project} </p><
        /div>
        <div className="folder cell">
          <p> - </p>
          <p> {name.cell} </p>
        </div>
        <div className="folder task">
          <p> - </p>
          <p> {name.task} </p>
        </div>
        <div className="folder stimulus">
          <p> - </p>
          <p> {name.stimulus} </p>
        </div>
      </div>

    </div>
)};

export default ExplorerHeader;
