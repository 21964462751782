import React from 'react';
import PanelMain from './main';
import PanelAside from './aside';
import Toolbar from '../toolbar';
import Videos from '../videos/Videos.jsx';
import Chart from '../chart';
import Editor from '../editor';

const Panel = (props) => {
  return (
    <div className="panel">
      <PanelMain>
        <Videos
          src={props.videopath}
          originalLength={props.videoLength}
          onTimeUpdate={props.onTimeUpdate}
          onPause={props.onPause}
          onPlay={props.onPlay}
          openFrameData={props.onSelect}
          data={props.chartData}
          stimulusPath={props.stimulusPath}
          fps={props.fps}
          stimuliFrames={props.stimuliFrames}
        />
        <Chart
          data={props.chartData}
          currentIndex={props.currentTime}
          onSelect={props.onSelect}
          onSelectRange={props.onSelectRange}
          isPlaying={props.playing}
          deselectAll={props.deselectAll}
          selected={props.selected}
          fps={props.fps}
        />
      </PanelMain>
      <PanelAside>
        <Toolbar
          title={props.filepath}
          onNormalize={props.onNormalize}
          onSave={props.onSave}
          onUndo={props.onUndo}
          onClose={props.onClose}
          touched={props.touched}
          isReadOnly={props.isReadOnly}
        />
        <Editor
          frames={props.frames}
          data={props.editorData}
          onEdit={props.onEdit}
          onCancel={props.onCancel}
          isReadOnly={props.isReadOnly}
        />
      </PanelAside>
    </div>
)};

export default Panel;
