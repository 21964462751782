import React, {Component} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import emitter from '../../emitter';

export default class Video extends Component {
  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying && this.props.isPlaying)
      this.player.play();
    else if (prevProps.isPlaying !== this.props.isPlaying && !this.props.isPlaying)
      this.player.pause();
  }

  componentWillUnmount() {
    emitter.off('getCurrentTime', this.getCurrentTime);
    emitter.off('setCurrentTime', this.setCurrentTime);
    emitter.off('setVideoSource', this.setVideoSource);
    if (this.player) {
      this.player.dispose();
    }
  }

  onPlayerStart = () => {
  //  console.log('started');
    window.isPlaying = true;
    emitter.emit('playStateChange', window.isPlaying);
    if (this.props.onPlayerStart) this.props.onPlayerStart();
    if (this.props.onPlay) this.props.onPlay();
  }

  onPlayerPause = () => {
  //  console.log('paused');
    window.isPlaying = false;
    emitter.emit('playStateChange', window.isPlaying);
    if (this.props.onPlayerPause) this.props.onPlayerPause();
    if (this.props.onPause) this.props.onPause();
  }

  onVideoEnd = () => {
  //  console.log('ended');
    window.isPlaying = false;
    if (this.props.onVideoEnd) this.props.onVideoEnd();
  }

  onTimeUpdate = () => {
    let time = this.player.currentTime();
    if (this.prev === time && time > 0.15) return;
    this.prev = time;
    if (this.props.id === 'respondent')
      emitter.emit('timeUpdated', time);
  }

  getCurrentTime = () => {
    let currentTime = this.player.currentTime();
    if (this.props.getCurrentTime)
      this.props.getCurrentTime(currentTime);
  }

  setCurrentTime = time => {
    this.player.currentTime(time);
  }

  setVideoSource = videoSource => {
    this.player.pause();

    if (this.props.id === 'respondent') {
      this.player.src(videoSource);
    }
  }

  init() {
    this.player = videojs(this.videoNode, this.props.options, () => {
      this.player.on('loadedmetadata', () => {
        if (this.props.setDuration) this.props.setDuration(this.player.duration());
        //window.isPlaying = true;
      });
    });
    this.player.on('play', this.onPlayerStart);
    this.player.on('pause', this.onPlayerPause);
    this.player.on('ended', this.onVideoEnd);
    this.player.on('timeupdate', this.onTimeUpdate);
    emitter.on('getCurrentTime', this.getCurrentTime);
    emitter.on('setCurrentTime', this.setCurrentTime);
    emitter.on('setVideoSource', this.setVideoSource);
  }

  render () {
    return (
      <div className="video">
        <div>
          <video ref={ node => this.videoNode = node } id={this.props.id} className="video-js vjs-default-skin"></video>
        </div>
      </div>
    )
  }
}
