class PubSub {
  /**
   * Creates new PubSub instance.
   */
  constructor() {
    this._listeners = {};
  }

  /**
   * Subscribes listener to the `type` event.
   *
   * @param {string} type
   * @param {Function} cb
   */
  on(type, cb) {
    this._listeners[type] =
      (this._listeners[type] || []).concat(cb);
  }

  /**
   * Unsubscribes listener from the `type` event.
   *
   * @param {string} type
   * @param {Function} cb
   */
  off(type, cb) {
    this._listeners[type] =
      (this._listeners[type] || []).filter((fn) => fn !== cb)
  }

  /**
   * Dispatches the `event` of `type` type.
   *
   * @param {string} type
   * @param {any} event
   */
  emit(type, event) {
    (this._listeners[type] || []).forEach((cb) => {
      cb(event);
    });
  }
};

export default PubSub;
