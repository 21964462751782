import React, {Component, Fragment} from 'react';
import File from './File.jsx';
import FileStateDialogue from '../modal/FileStateDialogue.jsx';

export default class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateDialogue: {visible: false, top: 0, left: 0, file: {}}
    }
  }

  openStateDialogue = (e, file) => {
    this.props.openFile(file);
    e.stopPropagation();
     if (file.state !== 'R' && file.state !== 'M' && !file.active) return;
    let stateDialogue = {
      visible: true,
      top: (e.clientY - 20) + 'px',
      left: (e.clientX + 35) + 'px',
      file
    }
    this.setState({stateDialogue});
  };

  closeStateDialogue = () => {
    let stateDialogue = {
      visible: false,
      top: '0px',
      left: '0px',
      file: {}
    }
    this.setState({stateDialogue});
  }

  outsideCloseStateDialogue = e => {
    if (this.fsd && !this.fsd.contains(e.target)) this.closeStateDialogue();
  }

  updateFileState = file => {
    this.props.update(file);
    this.closeStateDialogue();
  }

  setStateTmp = e => {
    let stateDialogue = JSON.parse(JSON.stringify(this.state.stateDialogue));
    stateDialogue.file.state = 'C';
    this.setState({stateDialogue});
  }

  render() {
    return (
      <Fragment>
        <span ref={node => this.fsd = node}>
          <FileStateDialogue {...this.state.stateDialogue}
                             closeStateDialogue={this.closeStateDialogue}
                             updateFileState={this.updateFileState}
                             setStateTmp={this.setStateTmp}
                             />
        </span>
        <ul className='explorer_list'>
          {
            this.props.data.map((file, i) => (
              <File key={i}
                    file={file}
                    openFile={() => this.props.openFile(file)}
                    openStateDialogue={this.openStateDialogue}
                    opened={this.props.openedFile.id === file.id}
                    disabled={file.active && this.props.openedFile.id !== file.id}/>
            ))
          }
        </ul>
      </Fragment>
    )
  }

  componentDidMount() {
    window.onclick = e => this.outsideCloseStateDialogue(e);
  }

  componentWillUnmount() {
    window.onclick = null;
  }
}
