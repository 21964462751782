import React, {Component} from 'react';
import {IconButton} from '../common';
import RespondentsVideo from './RespondentsVideo.jsx';
import StimulusVideo from './StimulusVideo.jsx';
import emitter from '../../emitter';
import moment from 'moment';
import {Title, Button} from '../../components/common';
import VideoLengthForm from '../../components/VideoLengthForm';

export default class Videos extends Component {
  constructor(props) {
    super(props);
    this.rAF = null;
    window.totalFrames = 0;
    window.currentFrame = 0;
    window.videoDuration = 0;
    this.state = {
      currentFrame: 0,
      elapsedTime: '00:00',
      stimulusCT: 0,
      isPlaying: false
    }
  };

  componentDidMount() {
    emitter.on('timeUpdated', this.onTimeUpdate);
    emitter.on('updateFrame', this.updateFrame);
    window.addEventListener('keydown', this.onKeyDown);
    this.sync();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentFrame !== prevState.currentFrame) window.currentFrame = this.state.currentFrame;
    if (this.props.src !== prevProps.src) {
      this.setState({currentFrame: 0, elapsedTime: '00:00', isPlaying: false});
      emitter.emit('setVideoSource', this.props.src);
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(window.rAF);
    emitter.off('timeUpdated', this.onTimeUpdate);
    emitter.off('updateFrame', this.updateFrame);
    window.removeEventListener('keydown', this.onKeyDown);
  };

  onKeyDown = (evt) => {
    console.log('keydown evt registered', evt.code, evt.target)
    const {code} = evt;
    let currentTime = this.calcCurrentTime(this.state.currentFrame);

    switch (code) {
      case 'Space':
        if (this.state.isPlaying) {
          this.setState({isPlaying: false});
        } else {
          this.setState({isPlaying: true});
        }
        break;

      case 'ArrowRight':
        emitter.emit('setCurrentTime', currentTime + 5);
        break;

      case 'ArrowLeft':
        emitter.emit('setCurrentTime', currentTime - 5);
        break;

      default: return;
    }
  };

  setDuration = duration => {
    window.videoDuration = duration;
    // window.totalFrames = duration * this.props.fps;
  }

  updateFrame = currentFrame => {
    this.setState({currentFrame});
    this.updatedFrame = true;
  }

  goToPreviousFrame = () => {
    let prevFrame = this.state.currentFrame - 1;
    if (prevFrame >= 0) {
      this.updatedFrame = true;
      emitter.emit('updateFrame', prevFrame)
      this.setState({currentFrame: prevFrame});
      this.props.openFrameData(this.props.data[prevFrame]);
    }
  }

  calcCurrentTime = frame => {
    return frame * window.videoDuration / window.totalFrames;
  }

  goToNextFrame = () => {
    let nextFrame = this.state.currentFrame + 1;
    if (nextFrame <= window.totalFrames) {
      this.updatedFrame = true;
      emitter.emit('updateFrame',nextFrame)
      this.setState({currentFrame: nextFrame});
      this.props.openFrameData(this.props.data[nextFrame]);
    }
  }

  sync = () => {
    let stimulusVideo = document.getElementById('stimulus_html5_api');
    let respondentVideo = document.getElementById('respondent_html5_api');
    if (!stimulusVideo || !respondentVideo) return;

    if (stimulusVideo.readyState === 4) {
      const T = respondentVideo.currentTime;
      stimulusVideo.currentTime = T;
    }

    window.rAF = window.requestAnimationFrame(this.sync);
  };

  setCurrentFrame = e => {
    let frame = Number(e.target.value);
    // eslint-disable-next-line
    if (frame !== frame || frame > window.totalFrames) return;
    window.currentFrame = frame;
    this.setState({currentFrame: frame});
  }

  onUpdateFrame = e => {
    e.preventDefault();
    window.currentFrame = this.state.currentFrame;
    emitter.emit('updateFrame', this.state.currentFrame);
    let frame = this.props.data.find(entry => entry.t === this.state.currentFrame);
    console.log('what is frame', frame)
    this.props.openFrameData(frame);
  }

  onTimeUpdate = time => {
   if (time <= 0.15) return;
   let currentFrame = Math.floor(time * this.props.fps);
   console.log(this.props.data, currentFrame)
   let elapsedTime = moment(time, "ss").format("mm:ss");
   this.setState({elapsedTime, currentFrame});
   let frame = this.props.data.find(entry => entry.t === this.state.currentFrame);
   this.props.openFrameData(frame);
  }

  onPlay = () => {
    this.setState({isPlaying: true});
  }

  onPause = () => {
    this.setState({isPlaying: false});
  }

  render() {
    return (
      <div className='video-box' id='media-materials'>
        <div className='video-wrap'>
          <RespondentsVideo src={this.props.src}
                            updateFrame={this.updateFrame}
                            setDuration={this.setDuration}
                            onPause={this.props.onPause}
                            onPlay={this.props.onPlay}
                            isPlaying={this.state.isPlaying}
                            fps={this.props.fps}/>
          <StimulusVideo stimulusPath={this.props.stimulusPath}/>
        </div>
        <div className="video-controls-wrapper">
          <div className='video-controls'>
            <div className="video-controls-left">
              <span>{this.state.elapsedTime}</span>
            </div>
            <div className="app-sidebar-title">
              <div className="vlf-wrapper">
                <Title style={{padding: '0px'}}>fps</Title>
                <VideoLengthForm val={this.props.fps}/>
              </div>
              <div className="vlf-wrapper">
                <Title style={{padding: '0px'}}>frames</Title>
                <VideoLengthForm val={this.props.stimuliFrames}/>
              </div>
            </div>
            <div className="video-controls-right">
              <IconButton title='previous frame' onClick={this.goToPreviousFrame}>
                <i className="material-icons">skip_previous</i>
              </IconButton>
              <form className="frames-number" onSubmit={this.onUpdateFrame}>
                <input type="text" title="frame" value={this.state.currentFrame} onChange={this.setCurrentFrame}/>
              </form>
              <IconButton title='next frame' onClick={this.goToNextFrame}>
                <i className="material-icons">skip_next</i>
              </IconButton>
            </div>
          </div>
          <div className="fake"></div>
        </div>

      </div>
    )
  }
}
