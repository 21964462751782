import {mapObject, clamp} from '../../utils/utils';

export const normalizeState = (key) => (state) => {
  return mapObject(state, (k) => (k === key) ? 1 : 0);
};

export const clampState = (name, value) => (state) => {
  const {neutral, [name]: x, ...rest} = state;
  // sum, minus neutral and current field.
  const excess = 1 - Object.keys(rest)
    .reduce((acc, cur) => acc + rest[cur], 0);
  const clampedValue = clamp(value, 0, excess);

  return {
    [name]: clampedValue,
    neutral: excess - clampedValue,
  };
};
