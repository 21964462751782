import firebase from 'firebase';

  const config = {
    apiKey: "AIzaSyDbTKxGY5H6f1fSa3DcIkBuYxTs8wIQG88",
    authDomain: "emo-viewer.firebaseapp.com",
    databaseURL: "https://emo-viewer.firebaseio.com",
    projectId: "emo-viewer",
    storageBucket: "emo-viewer.appspot.com",
    messagingSenderId: "1003238530220",
    appId: "1:1003238530220:web:01130d4069b4c78d"
  };
firebase.initializeApp(config);
export default firebase;
