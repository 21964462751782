import React, {Component, Fragment} from 'react';
import {fileStates} from '../../utils/file-states.js';
import {removeAt} from '../../utils/utils';
import Explorer from '../../components/explorer';
import Toast from '../../components/Toast';
import StateLegend from '../../components/StateLegend.jsx';
import Window from '../window';
import {Title, Button, IconButton} from '../../components/common';
import VideoLengthForm from '../../components/VideoLengthForm';
import Nav from '../../components/modal/Nav.jsx';
import {db} from '../../data/files';
import {api} from '../../api';
import { saveAs } from 'file-saver';
import moment from 'moment';
import firebase from 'firebase';

class Homepage extends Component {
  state = {
    filepath: '',
    messages: [],
    exporting: false,
    isModalVisible: false,
    docs: [],
    selectedDir: {project: '', cell: '', task: '', stimulus: ''},
    count: {},
    filtered: ['modified', 'active', 'cleaned', 'rejected', 'free'],
    stimulusPath: '',
    fps: 20,
    stimuliFrames: 0
  };

  resetStimulusData = () => {
    this.setState({fps: 20, stimuliFrames: 0});
  }

  updateFPS = fps => {
    this.setState({fps});
  };

  updateStimuliFrames = stimuliFrames => {
    this.setState({stimuliFrames});
    window.totalFrames = stimuliFrames;
  };

  showNavModal = () => {
    this.setState({isModalVisible: true});
  }

  onMessage = msg => {
    this.setState({
      messages: this.state.messages.concat(msg),
    });
  };

  openFile = file => {
    this.setState({
      filepath: file,
      isReadOnly: fileStates[file.state].disabled
    });
  };

  closeFile = () => {
    this.resetStimulusData();
    if (window.openedFile && window.openedFile.id !== -1) {
      db.update(window.openedFile.id, {active: false});
    }
    this.setState({
      filepath: '',
    });
  };

  dismiss = (index) => {
    this.setState({
      messages: removeAt(this.state.messages, index),
    });
  };

  onSave = () => {
    const {filepath} = this.state;
    let file = JSON.parse(JSON.stringify(filepath));
    file.state = 'M';
    db.update(filepath.id, file);
  }

  onExport = () => {
    this.setState({exporting: true});
    api.export({...this.state.selectedDir, emo_user_key: localStorage.getItem('emo_user_key')})
      .then(response => {
        let ct = new Date();
        ct = moment(ct).format('DD.MM.YYYY. HH:mm:ss');
        let blob = new Blob([response.data], {type: "text/csv"});
        saveAs(blob, `${this.state.selectedDir.cell}_${this.state.selectedDir.task}_${this.state.selectedDir.stimulus}_${ct}.csv`);
        this.setState({exporting: false});
    });
  };

  onDownloadMP4 = () => {
    this.setState({exporting: true});
    api.downloadVideos({...this.state.selectedDir, emo_user_key: localStorage.getItem('emo_user_key'), type: 'mp4'})
      .then(response => {
        let ct = new Date();
        ct = moment(ct).format('DD.MM.YYYY. HH:mm:ss');
        let blob = new Blob([response.data], {type: "application/octet-stream"});
        saveAs(blob, `${this.state.selectedDir.cell}_${this.state.selectedDir.task}_${this.state.selectedDir.stimulus}_${ct}.zip`);
        this.setState({exporting: false});
    });
  };

  onDownloadWEBM = () => {
    this.setState({exporting: true});
    api.downloadVideos({...this.state.selectedDir, emo_user_key: localStorage.getItem('emo_user_key'), type: 'webm'})
      .then(response => {
        let ct = new Date();
        ct = moment(ct).format('DD.MM.YYYY. HH:mm:ss');
        let blob = new Blob([response.data], {type: "application/octet-stream"});
        saveAs(blob, `${this.state.selectedDir.cell}_${this.state.selectedDir.task}_${this.state.selectedDir.stimulus}_${ct}.zip`);
        this.setState({exporting: false});
    });
  };

  hideModal = () => {
    this.setState({isModalVisible: false});
  }

  onGetTesters = (docs, selectedDir) => {
    let {project, cell, task, stimulus} = selectedDir;
    db.getAll().then(files => {
      let stimulusPath = docs.stimulus;
      let count = {total: 0, C: 0, R: 0};
      let filtered = files.filter(file => {
        if(file.project === project && file.cell === cell && file.task === task && file.stimulus === stimulus) {
          if (count[file.state]) count[file.state] += 1;
          else count[file.state] = 1;
          count.total++;
          return true;
        };
        return false;
      }).map(file => file.userid);
      let add = docs.testers.filter(doc => filtered.indexOf(doc) < 0);
      add.forEach(doc => {
        db.add({
          project,
          cell,
          task,
          stimulus,
          userid: doc,
          state: '',
          reject_reason: '',
          reject_reason_other: ''
        })
      });
      this.setState({docs, selectedDir, count, stimulusPath});
      this.hideModal();
    });
  }

  updateCount = count => {
    this.setState({count});
  }

  updateReadOnly = fileState => {
    if (fileState === 'C' || fileState === 'R')
      this.setState({isReadOnly: true});
    else
      this.setState({isReadOnly: false});
  }

  filterFiles = filtered => {
    this.setState({filtered});
  }

  getData = (selectedDir = this.state.selectedDir) => {
    this.closeFile();
    api.fetchTesters({emo_user_key: localStorage.getItem('emo_user_key'), ...selectedDir})
      .then(response => {
        this.onGetTesters(response.data, selectedDir);
      })
  }

  refresh = () => {
    this.getData();
  }

  render() {
    const {
      videoLength,
      exporting,
      filepath,
      messages
    } = this.state;

    return (
      <Fragment>
        <Nav isVisible={this.state.isModalVisible} hideModal={this.hideModal} getData={this.getData}/>
        <aside className='app-sidebar'>
          <span>
            <Title style={{padding: '0 12px', 'justifyContent': 'space-between'}}>
              <span>explorer</span>
              <span>
                <IconButton title="Change project" onClick={this.showNavModal}>
                  <i className="material-icons projects-headline">view_headline</i>
                </IconButton>
                <IconButton title="Refresh" onClick={this.refresh}>
                  <i className="material-icons">refresh</i>
                </IconButton >
              </span>
            </Title>
          </span>
          <Explorer
            filepath={filepath}
            onClick={this.openFile}
            selectedDir={this.state.selectedDir}
            docs={this.state.docs}
            filtered={this.state.filtered}
            onUpdateCount={this.updateCount}
            updateReadOnly={this.updateReadOnly}
          />
          <StateLegend count={this.state.count} filterFiles={this.filterFiles}/>
          <div className='app-sidebar_footer'>
            <div className="footer-section">
              <Button
                className='block btn--primary'
                onClick={this.onExport}
                disabled={exporting || (this.state.count.C + this.state.count.R !== this.state.count.total)}
              >export csv</Button>
              <Button
                className='block btn--primary'
                onClick={this.onExport}
                disabled={true}
              >calculate KPIs</Button>
            </div>
            <div className="footer-section bottom">
              <Button
                className='block'
                onClick={this.onDownloadMP4}
                disabled={exporting}
              >download mp4</Button>
              <Button
                className='block'
                onClick={this.onDownloadWEBM}
                disabled={exporting}
              >download webm</Button>
            </div>
          </div>
        </aside>
        <div className="frame">
          {
            filepath &&
            <Window
              key={filepath}
              filepath={filepath}
              videoLength={videoLength}
              onMessage={this.onMessage}
              onClose={this.closeFile}
              onSave={this.onSave}
              stimulusPath={this.state.stimulusPath}
              isReadOnly={this.state.isReadOnly}
              updateFPS={this.updateFPS}
              fps={this.state.fps}
              stimuliFrames={this.state.stimuliFrames}
              updateStimuliFrames={this.updateStimuliFrames}
            />
          }
        </div>

        <div className="toast-container">
          {
            messages.map((message, i) =>
            <Toast
              key={i}
              message={message}
              onDismiss={() => this.dismiss(i)}
            />)
          }
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    if (this.props.selectedDir) {
      let {project, cell, task, stimulus} = this.props.selectedDir;
      if (project && cell && task && stimulus) {
        let dir = {project, cell, task, stimulus, stimuli: stimulus};
        api.fetchTesters({emo_user_key: localStorage.getItem('emo_user_key'), ...dir})
          .then(response => {
            this.onGetTesters(response.data, dir);
          })
      }
      return;
    }
    this.setState({isModalVisible: true});
    db.listenForChanges();
  }
}

export default Homepage;
