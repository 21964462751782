// eslint-disable-next-line
import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {auth} from './utils/auth.js';

export default function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route {...rest} render={props => auth.isLoggedIn() || localStorage.getItem('authUser') ? <Component {...props} {...rest}/> : <Redirect to="/" />} />
  )
}
