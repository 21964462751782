import React, {Component} from 'react';
import draggable from './draggable.js';

export default class Dots extends Component {
  componentDidMount() {
    let from = document.getElementById('dot-from');
    let to = document.getElementById('dot-to');
    let w = document.querySelector('.chart').getBoundingClientRect().width + 64 - from.getBoundingClientRect().width;
    draggable(from, null, {limit: {from: () => 58, to: () => w}});
    draggable(to, null, {limit: {from: () => 58, to:() =>  w}});
  }

  static getDot(dotID) {
    return document.getElementById(dotID);
  }

  static setStyle(dot, style) {
    for (let prop in style) {
      dot.style[prop] = style[prop];
    }
  }

  render () {
    return (
      <span>
        <div id="dot-from" className="dot"></div>
        <div id="dot-to" className="dot"></div>
      </span>
    )
  }
}
